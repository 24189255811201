<template>
  <div >

  <header>
      
    <div style="width: 100%; overflow: hidden;">
      <div style="width: 10%; float: left;">
          <img src="../assets/img_tipo_cem_e.png" alt="" width="60%" align="left" class="figlado"/> 
          <img src="../assets/img_tipo_cem_ee.png" alt="" width="80%" align="left" class="figlado" style="margin-top:24px;margin-left:24px"/>

      </div>
      
      <div style="width: 80%;  float: left;margin-top:5%"> 
          <div class="container">
          <div class="logomarca">
            <img src="../assets/marca_top_cemiterio.png"  style="max-width:90%"  alt="" />
          </div>
          <div class="text">
            <h1>Velórios Virtuais</h1>
            <h3>Você por perto, mesmo </br> estando longe.</h3>
          </div>
        </div>
      </div>
      
      <div style="width: 10%;  float: left;"> 
          <img src="../assets/img_tipo_cem_d.png" alt="" width="60%" align="right" class="figlado" />
      </div>
    </div>


    </header>

    <div style="width: 100%; overflow: hidden;">
      <div style="width: 10%; float: left;height: 100vh;padding-top:60vh;">
                <img src="../assets/img_cemiterio_lde.png" alt="" width="250%"  class="figlado"/>

      </div>
      
      <div style="width: 80%;  float: left;"> 
          <section class="s-hero">
              <iframe 
            src=" https://www.adiau.net/validacao/index.php?ipc=1672700300&id=167&token=cc5028bc7b3dfd2e3470f14dc274947d&color1=e4f2ff&color2=005487*&color3=******&color4=******&color5=******#"
                title="Velório Virtual"
                frameborder="0" style="width:100%;height:80vh;background-color:#9fc1d7"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
          </section>
      </div>
      
      <div style="width: 10%;  float: left;height: 100vh;"> 
          <img src="../assets/img_cemiterio_ldc.png" alt="" width="50%" align="right" style="margin-top:4rem" class="figlado"/>
      </div>
    </div>




<!--

  <div class="contact">
            <img src="../assets/buque-de-flores.png" alt="" />
            <h3>Floricultura:</h3>
            <p>Telefone para vendas:</p>
            <span>(82) 9.9999-9999</span>
          </div>
-->
   <section class="s-contact">
      <div class="container">
        <div class="contacts-left">
         <div class="contact">
            <div style="width: 370px; overflow: hidden;margin-left:20%">
              <div style="width: 20%; float: left;background-color:#005487">
                <img src="../assets/cuidado_cemiterio_icone.png" alt="" max-width="10px"/>
              </div>
              <div style="width: 70%;  float: left;margin-left:14px;"> 
                  <h3>PLANOS - </b></br>CEMITÉRIO </br> PREVIDA:</h3><BR/>
                  <p>Atendimento 24h:</p>
                  <span>0800 082 9000</span>
              </div>
            </div>     
          </div>
        </div>

        <div class="contacts-right">
          <div class="contact">
            <div style="width: 370px; overflow: hidden;margin-left:20%;">
              <div style="width: 20%; float: left;background-color:#005487">
                <img src="../assets/especialista_cemiterio_icone.png" alt="" />
              </div>
              <div style="width: 75%;  float: left;padding-left:14px;"> 
                <h3>FALE COM UM </br> ESPECIALISTA:</h3><BR/>
                <p>Central de vendas </br>(Whatsapp):</p>
                <span>0800 082 9000</span>
              </div>
            </div>  

          </div>

        </div>
      </div>
    </section>
     
    <footer>
      <div class="container">
      <div class="social">
        <div class="icones">

            <v-btn href="https://api.whatsapp.com/send?phone=8000829000&text=Acesso%20via%20site%3A%20Ol%C3%A1%2C%20quero%20entrar%20em%20contato%20com%20o%20Cemitério%20Previda" target="_blank" color="#005487" fab  x-large  dark>
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>

            <v-btn href="https://www.instagram.com/cemiterioprevida/" target="_blank" color="#005487" fab  x-large  dark>
              <v-icon>mdi-instagram</v-icon>
            </v-btn>

            <v-btn href="https://www.facebook.com/previdaarapiraca"  target="_blank" color="#005487" fab  x-large  dark>
              <v-icon>mdi-facebook</v-icon>
            </v-btn>

        </div>
      </div>
      </div>

      <div style="width: 100%; overflow: hidden;">
       <div style="width: 10%; float: left;">
              <div> <img src="../assets/img_cemiterio_baixo_ee.png" width="90%"  style="margin-left:32px"  alt="" align="left" class="figlado"/> </div>
              
              <div> <img src="../assets/img_cemiterio_baixo_e.png" width="90%"  style="margin-top:90px;"  alt="" align="left" class="figlado"/> </div>

      </div>
      
      <div style="width:80%;float:left;margin-top:100px;"> 
            
           <div class="container">
                     
                <div class="social">
                  <div class="text">
                    <p>RODOVIA AL-115, KM 8, </br> GUARIBAS ARAPIRACA - AL</p>
                    <p>{{ new Date().getFullYear() }} -<strong> CEMITÉRIO PREVIDA</strong></p>
                  </div>
              </div>
          
            </div>

        </div>
      
      <div style="width: 10%;  float: left;"> 
          <img src="../assets/img_cemiterio_baixo_d.png" alt="" width="120%" align="right" class="figlado"/>
      </div>

    </div>
    </footer>

  </div>  
</template>

<script>

export default {

data: () => ({
      //color: 'background-color:#f5f5f5;',
            icons: [
                //'mdi-facebook',
                //'mdi-whatsapp',
                //'mdi-instagram',
            ],

  }),



}
</script>

<style scoped> 
*{
  --background: url('../assets/fundo_button_memorial.png');
  padding:0;
  list-style:none;
  text-decoration:none;
  outline:none;
  border:none;
  box-sizing:border-box
  }

  hr{
  border: 2px solid orange;
  border-radius: 5px;
  width:50%;
  margin-left:25%;

  }


  @media screen and (max-width: 1000px) {
    .logomarca{
      max-width:200px
    }    
  }  
    .logomarca{
      width:240px;
    }

  img{
    line-height: normal;
    }
  html{
    font-size:22.5%
    }


 
  @media (max-width: 1300px){
    
    html{
      font-size:18%
      }
  }
  @media (max-width: 90px){
     
    html{
      font-size:1.5%
      }
  }@media (max-width: 20px){
    html{
      font-size:28%
      }
  }
  html,body{
    overflow-x:hidden
    }
  button{
    background-color:transparent;
    cursor:pointer
  }
  .container{
    width:100%;
    max-width:147rem;
    padding:1.5rem;
    color:#005487; 
   margin:0 auto
  }
  header{
    padding:0rem 0;
  }
  header .container{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
  }
  header .container .text h1{
    text-transform:uppercase;
    font-size:1.8rem;
    margin-bottom:0.1rem;
    text-align:center;
    background:#e4f2ff;
    color: #005487;
    letter-spacing:0.2rem;
    font-family:"poppins-black";

  }
  header .container .text h3{
    font-size:1.5rem;
    color: #005487;
    text-align:center;
    letter-spacing:0.1rem;
    font-family:"poppins";

  }
  @media (max-width:485px){ .figlado{ visibility: hidden; } }
  @media (max-width: 100px){
    header .container{
      flex-direction:column;}
  }
  @media (max-width: 480px){
    header .container .text h1{
      font-size:1.2rem
    }
    header .container .text h3{
      font-size:1rem;
      }
    }

    footer{
      position:relative;

      padding-bottom:0
    }
    footer .container{
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:center;
      padding-bottom:0;
      height:1rem;
    }
    footer .container .img-left{
      position:absolute;
      bottom:0;
      left:0
    }
    footer .container .img-right{
      position:absolute;
      bottom:0;
      right:0
    }
    footer .container .social{
      width:50%
    }
    footer .container .social .icones{
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:space-around;
      width:100%
    }
    footer .container .social .icones .icone{
      background:#005487;
      width:74px;
      height:74px;
      padding:1.2rem;
      border-radius:25%
      }
      footer .container .social .text{
        margin-top:0.1rem
      }
      footer .container .social .text p{
        text-align:center;
        font-size:1rem;
        font-family:'poppins';
        color:#005487;
        letter-spacing:0.2rem
      }
      footer .container .social .text p:last-child{
        margin-top:1rem
      }
      @media (max-width: 1160px){
        footer .container{
          height:55rem
        }
        footer .container .img-left,footer .container .img-right{
          max-width:25%
          }
        footer .container .social .text p{
          font-size:1rem
        }
      }
      
      @media (max-width: 480px){
        footer .container{
          height:12rem
        }
        footer .container .social{
          width:100%
        }
      }
      .s-hero .container{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        padding:2rem 0;
      }
      
      .s-hero .container iframe{
        width:100%;max-width:98%;height:45rem;
      }

      .s-contact{
        padding:0.1rem 0
      }
      .s-contact .container{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-around
      }
      .s-contact .container .contacts-left .contact,.s-contact .container .contacts-right .contact{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        margin-bottom:0.1rem;
        padding-bottom:5rem
      }.s-contact .container .contacts-left .contact:last-child,.s-contact .container .contacts-right .contact:last-child{
        margin-bottom:0
        }.s-contact .container .contacts-left .contact img,.s-contact .container .contacts-right .contact img{
          width:72px;height:72px;margin-left:0.1rem;font-family:'poppins';padding:0.6rem;border-radius:25%;
        }
        .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
        font-size:1.2rem;font-family:'poppins-black';color:#005487;line-height: normal;text-transform:uppercase}
        .s-contact .container .contacts-left .contact p,.s-contact .container .contacts-right .contact p{
          font-size:1rem;padding-left:0.1rem;color:#005487;font-family:'poppins';margin-top:0.2rem
        }
        .s-contact .container .contacts-left .contact span,.s-contact .container .contacts-right .contact span{
          margin-left:0.1rem;font-size:1.2rem;font-family:'poppins';color:#005487; background:#e4f2ff;
padding:0.1rem;margin-top:0.1rem
          }
        @media (max-width: 1000px){
          .s-contact{padding:0}.s-contact .container{
            align-items:center;
            flex-direction:column;
            font-family:'poppins';
            color:#005487;
            align-items:center;
            }
          .s-contact .container .contacts-left .contact,.s-contact .container .contacts-right .contact{
            align-items:center;margin:0
          }
          .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
            text-align:left
            }
          }
        @media (max-width: 80px){
          .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
            font-size:2rem;
            font-family:'poppins';
            color:#005487;
            }
          }



 
    @font-face {
    font-family: 'poppins-black';
    src: url(../assets/fonts/Poppins-Black.ttf);
    }

    @font-face {
    font-family: 'poppins';
    src: url(../assets/fonts/Poppins-Light.ttf);
    }




</style>

<template>
  <div style="background-image: linear-gradient(15deg, #fdf3f2 0, #efecec 25%, #dfe5e6 50%, #d0dee1 75%, #c1d7dd 100%);" class="tudo">
  <header >
      
                  <h1>Velórios Virtuais</h1>
                  <hr>

         
      
    </header>
     <section class="s-hero">
      <div class="container">
        <div class="icones">
            <router-link to="/parquedasflores"><img style="width:180px" src="./assets/LOGOSGPF256Prancheta 1.png" > </router-link>
            <router-link to="/previda"><img style="width:180px;margin-left:20px" src="./assets/LOGOSGPF256Prancheta 2.png" > </router-link>
            <router-link to="/memorialparquemaceio"><img style="width:180px;margin-left:20px" src="./assets/LOGOSGPF256Prancheta 3.png" > </router-link>
            <router-link to="/cemiterioprevida"><img style="width:180px;margin-left:20px" src="./assets/LOGOSGPF256Prancheta 4.png" > </router-link>
            <router-link to="/parquedoagreste"><img style="width:180px;margin-left:20px" src="./assets/LOGOSGPF256Prancheta 5.png" > </router-link>
        </div>
      </div>
    </section>

   
  <div class="img_central" >
          <img src="./assets/LOGOSGPF256Prancheta 6.png" class="img_central"  alt="" />
        </div>  
        
    <footer>
          <div class="text" style="margin-top:4rem;">
                {{ new Date().getFullYear() }} — <strong>Grupo Parque das Flores </strong>
          </div>
    </footer>

  </div>  
</template>

<script>

export default {

data: () => ({
      //color: 'background-color:#f5f5f5;',
            icons: [
                //'mdi-facebook',
                //'mdi-whatsapp',
                //'mdi-instagram',
            ],

  }),



}
</script>


<style scoped> 

*{
 
  background-repeat: no-repeat;
  padding:0;
  list-style:none;
  text-decoration:none;
  text-align:center;
  outline:none;
  border:none;
  box-sizing:border-box;
  font-family:"coldiac";

  }


  logo{
       flex-direction:row;
    align-items:center;
  }

  hr{
  border: 2px solid #565d68;
  border-radius: 5px;
  width:50%;
  margin-left:25%;

  }

  .tudo{
    height: 100%;
  }


  @media screen and (max-width: 1000px) {
    .logomarca{
      max-width:200px
    }
      
  }  
    .logomarca{
      width:240px;
    }

  img{
    line-height: normal;
    max-width:200px;    
    }

    .img_central{

        justify-content:center;

    }
  html{
    font-size:22.5%;
    
    }


 
  @media (max-width: 1300px){
    
    html{
      font-size:18%
      }
      .container{
        width:100%;
        max-width:200px;
        padding:1.5rem;
        color:#464646; 
        margin:0 auto

    }
  }
  @media (max-width: 90px){
     
    html{
      font-size:1.5%
      }
  }@media (max-width: 20px){
    html{
      font-size:28%
      }
  }
  html,body{
    overflow-x:hidden
    }
  button{
    background-color:transparent;
    cursor:pointer
  }
  .container{
    width:100%;
    padding:1.5rem;
    color:#464646; 
   margin:0 auto
  }
  header{
    padding:5rem 0;
    text-align:center;
    font-size:1rem;
    font-family:"coldiac";
    justify-content:center;
  }
  header .container{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
  }
  header .container .text h1{
    text-transform:uppercase;
    font-size:2.8rem;
    margin-bottom:0.1rem;
    text-align:center;
        letter-spacing:0.2rem;
    font-family:"coldiac";
    color:#565d68;

  }
  header .container .text h3{
    font-size:1.3rem;
    color: white;
    text-align:center;
    background:#9fc1d7;
    letter-spacing:0.1rem;
    font-family:"coldiac";
    color:#565d68;

  }
  @media (max-width:485px){ .figlado{ visibility: hidden; } }
  @media (max-width: 100px){
    header .container{
      flex-direction:column;}
  }
  @media (max-width: 480px){

   
    header .container .text h1{
      font-size:1.2rem
    }
    header .container .text h3{
      font-size:1rem;
      }
    }

    footer{
      position:relative;

      padding-bottom:0
    }
    footer .container{
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:center;
      padding-bottom:0;
      height:12rem
    }
    footer .container .img-left{
      position:absolute;
      bottom:0;
      left:0
    }
    footer .container .img-right{
      position:absolute;
      bottom:0;
      right:0
    }
    footer .container .social{
      width:50%
    }
    footer .container .social .icones{
      display:flex;
      flex-direction:row;
      max-width:180px;
      align-items:center;
      background-color:blue;
      justify-content:center;
      width:100%
    }
    footer .container .social .icones .icone{
      background:#0a5587;
      background-color:blue;
            justify-content:center;
      max-width:180px;
      width:74px;
      height:74px;
      padding:1.2rem;
      border-radius:25%
      }
      footer .container .social .text{
        margin-top:0.1rem
      }
      footer .container .social .text p{
        text-align:center;
        font-size:1rem;
        font-family:'coldiac';
        color:#464646;
        letter-spacing:0.2rem
      }
      footer .container .social .text p:last-child{
        margin-top:1rem
      }
      @media (max-width: 1160px){
          
        footer .container{
          height:55rem
        }
        footer .container .img-left,footer .container .img-right{
          max-width:25%
          
          }
        footer .container .social .text p{
          font-size:1rem
        }
      }
      
      @media (max-width: 480px){
        footer .container{
          height:12rem
        }
        footer .container .social{
          width:100%
        }
      }
      .s-hero .container{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        padding:2rem 0;
      }
      
      .s-hero .container iframe{
        width:100%;max-width:98%;height:45rem
      }

      .s-contact{
        padding:1rem 0
      }
      .s-contact .container{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-around
      }
      .s-contact .container .contacts-left .contact,.s-contact .container .contacts-right .contact{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        margin-bottom:0.1rem;
        padding-bottom:5rem
      }.s-contact .container .contacts-left .contact:last-child,.s-contact .container .contacts-right .contact:last-child{
        margin-bottom:0
        }.s-contact .container .contacts-left .contact img,.s-contact .container .contacts-right .contact img{
          width:96px;height:96px;margin-left:0.1rem;font-family:'coldiac';padding:0.6rem;border-radius:25%;
        }
        .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
        font-size:1.2rem;font-family:'coldiac';color:#464646;line-height: normal;text-transform:uppercase}
        .s-contact .container .contacts-left .contact p,.s-contact .container .contacts-right .contact p{
          font-size:1rem;padding-left:0.1rem;color:#464646;font-family:coldiac;margin-top:0.2rem
        }
        .s-contact .container .contacts-left .contact span,.s-contact .container .contacts-right .contact span{
          margin-left:0.1rem;font-size:1.2rem;font-family:'coldiac';color:#464646;padding:0.1rem;margin-top:0.1rem
          }
        @media (max-width: 1000px){
            
          .s-contact{padding:0}.s-contact .container{
            align-items:center;
            flex-direction:column;
            font-family:'coldiac';
            color:#464646;
            align-items:center;
            }
          .s-contact .container .contacts-left .contact,.s-contact .container .contacts-right .contact{
            align-items:center;margin:0
          }
          .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
            text-align:left
            }
          }
        @media (max-width: 80px){
          .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
            font-size:2rem;
            font-family:'coldiac';
            color:#464646;
            }
          }



 
    @font-face {
    font-family: 'coldiac';
    font-style: normal;
    font-weight: normal;
    src: local('Coldiac Italic'), url(./assets/fonts/Coldiac Italic.ttf) format('ttf');
    }





</style>


<template>
  <div style="background-color:#eef6df;">
  <header>
      <div class="container">

        <div class="logomarca">
          <img src="../assets/marca_top_memorial.png"  style="max-width:80%"  alt="" />
        </div>
        <div class="text">
          <h1>Velórios Virtuais</h1>
          <h3>Você presente </br>mesmo distante.</h3>
        </div>
      </div>
    </header>

    <div style="width: 100%; overflow: hidden;">
      <div style="width: 10%; float: left;height: 100vh;padding-top:60vh;">
        <img src="../assets/elemento_ramo_e.png" alt="" width="100%"/>
      </div>
      <div style="width: 80%;  float: left;"> 

        <section class="s-hero">
            <iframe
          src="https://www.adiau.net/validacao/index.php?ipc=1692704302&id=169&token=e5db59c932f6151b8820b1644bc5c72b&color1=d0e5ce&color2=******&color3=******&color4=******&color5=******#"
              title="Velório Virtual"
              frameborder="0" style="width:100%;height:100vh"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
        </section>
      </div>
    <div style="width: 10%;  float: left;height: 100vh;"> 
        <img src="../assets/elemento_ramo_d.png" alt="" width="100%"/>
    </div>

</div>


<!--

  <div class="contact">
            <img src="../assets/buque-de-flores.png" alt="" />
            <h3>Floricultura:</h3>
            <p>Telefone para vendas:</p>
            <span>(82) 9.9999-9999</span>
          </div>
-->
   <section class="s-contact">
      <div class="container">
        <div class="contacts-left">
         <div class="contact">
            <div style="width: 320px; overflow: hidden;">
              <div style="width: 25%; float: left;">
                <img src="../assets/buque-de-flores.png" alt="" />
              </div>
              <div style="width: 75%;  float: left;text-align:left;"> 
                  <h3>FLORICULTURA &nbsp;<b>&middot;</b> </br> MEMORIAL </br> FLORES</h3>
                  <p>Telefone para vendas:</p>
                  <span>(82) 3352-4202</span>
              </div>
            </div>     
          </div>
        </div>

        <div class="contacts-right">
          <div class="contact">

            <div style="width: 320px; overflow: hidden;">
              <div style="width: 25%; float: left;">
                <img src="../assets/cuidados-de-saude.png" alt="" />
              </div>
              <div style="width: 75%;  float: left;"> 
                <h3>PLANOS &nbsp;<b>&middot;</b> </br>MEMORIAL</br> PARQUE MACEIO</h3>
                <p>Central de Vendas (Whatsapp):</p>
                <span>(82) 99122-9510</span>
              </div>
            </div>  

          </div>

        </div>
      </div>
    </section>

    <footer>
      <div class="container">
      
        <div class="social">
         
         <!-- <div class="icones">
            <a href="https://api.whatsapp.com/send?phone=5582982121700&text=Acesso%20via%20site%3A%20Ol%C3%A1%2C%20quero%20entrar%20em%20contato%20com%20o%20Previda" target="_blank"><img src="../assets/img/whatsapp.png" alt="" class="icone" /></a>
            <a href="https://www.instagram.com/previda_/" target="_blank"><img src="../assets/img/instagram.png" alt="" class="icone" /></a>
            <a href="https://www.facebook.com/PrevidaAlagoas" target="_blank"><img src="../assets/img/facebook.png" alt="" class="icone" /></a>
        
          </div>
          -->
            <div class="icones">
         
            <v-btn href="https://api.whatsapp.com/send?phone=5582991229510&text=Acesso%20via%20site%3A%20Ol%C3%A1%2C%20quero%20entrar%20em%20contato%20com%20o%20Memorial%20Parque%20Maceió" target="_blank" color="#218d2a" fab  x-large  dark>
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>

            <v-btn href="https://www.instagram.com/memorialparque/" target="_blank" color="#218d2a" fab  x-large  dark>
              <v-icon>mdi-instagram</v-icon>
            </v-btn>

            <v-btn href="https://www.facebook.com/parquedasfloresmaceio"  target="_blank" color="#218d2a" fab  x-large  dark>
              <v-icon>mdi-facebook</v-icon>
            </v-btn>


          </div>
          <div class="text">
            <p>Av. de Contorno, s/n - Benedito Bentes, </br> Maceió - AL, 57084-646</p>
            <p>{{ new Date().getFullYear() }} -<strong> Memorial Parque Maceió</strong></p>
          </div>
        </div>
     
      </div>
    </footer>

  </div>  
</template>

<script>

export default {

data: () => ({
      //color: 'background-color:#f5f5f5;',
            icons: [
                //'mdi-facebook',
                //'mdi-whatsapp',
                //'mdi-instagram',
            ],

  }),



}
</script>

<style scoped> 
*{
  margin:0;
  padding:0;
  list-style:none;
  text-decoration:none;
  outline:none;
  border:none;
  box-sizing:border-box
  }


  @media screen and (max-width: 1000px) {
    .logomarca{
      max-width:200px
    }
    
  }  
  .logomarca{
    width:240px;
  }


  img{
    display:block;
    line-height: normal;
    }
  html{
    font-size:22.5%
    }


 
  @media (max-width: 1300px){
    
    html{
      font-size:18%
      }
  }
  @media (max-width: 90px){
     
    html{
      font-size:1.5%
      }
  }@media (max-width: 20px){
    html{
      font-size:28%
      }
  }
  html,body{
    overflow-x:hidden
    }
  button{
    background-color:transparent;
    cursor:pointer
  }
  .container{
    width:90%;
    max-width:147rem;
    padding:1.5rem;
    margin:0 auto
  }
  header{
    background-image: url("../assets/fundo_top_memorial.png");
    padding:3rem 0;
  }
  header .container{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
  }
  header .container .text h1{
    text-transform:uppercase;
    background:#d3e7ad;
    font-size:2rem;
    margin-bottom:0.1rem;
    color:#185c27;
    text-align:center;
        font-family:"Hero3";

  }
  header .container .text h3{
    font-size:1.2rem;
    color: #185c27;
    text-align:center;
    letter-spacing:0.3rem
  }
  @media (max-width: 100px){
    header .container{
      flex-direction:column}
  }
  @media (max-width: 480px){
    header .container .text h1{
      font-size:1.2rem
    }
    header .container .text h3{
      font-size:1rem;
      }
    }
    footer{
      position:relative;
      background-image: url("../assets/fundo_button_memorial.png");
      padding-bottom:0
    }
    footer .container{
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:center;
      padding-bottom:0;
      height:25rem
    }
    footer .container .img-left{
      position:absolute;
      bottom:0;
      left:0
    }
    footer .container .img-right{
      position:absolute;
      bottom:0;
      right:0
    }
    footer .container .social{
      width:50%
    }
    footer .container .social .icones{
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:space-around;
      width:100%
    }
    footer .container .social .icones .icone{
      background:#0a5587;
      width:74px;
      height:74px;
      padding:1.2rem;
      border-radius:25%
      }
      footer .container .social .text{
        margin-top:4rem
      }
      footer .container .social .text p{
        text-align:center;
        font-size:1rem;
        color:#218d2a;
        letter-spacing:0.2rem
      }
      footer .container .social .text p:last-child{
        margin-top:1rem
      }
      @media (max-width: 1160px){
        footer .container{
          height:55rem
        }
        footer .container .img-left,footer .container .img-right{
          max-width:25%
          }
        footer .container .social .text p{
          font-size:1rem
        }
      }
      
      @media (max-width: 480px){
        footer .container{
          height:35rem
        }
        footer .container .social{
          width:100%
        }
      }
      .s-hero .container{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        padding:2rem 0
      }
      .s-hero .container iframe{
        width:100%;max-width:98%;height:45rem
        }
      .s-contact{
        padding:1rem 0
      }
      .s-contact .container{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-around
      }
      .s-contact .container .contacts-left .contact,.s-contact .container .contacts-right .contact{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        margin-bottom:0.1rem;
        padding-bottom:5rem
      }.s-contact .container .contacts-left .contact:last-child,.s-contact .container .contacts-right .contact:last-child{
        margin-bottom:0
        }.s-contact .container .contacts-left .contact img,.s-contact .container .contacts-right .contact img{
          background:#d0e5ce;width:72px;height:72px;margin-left:0.1rem;padding:0.6rem;border-radius:25%;
        }
        .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
        font-size:1.2rem;font-family:'Hero3';color:#218d2a;line-height: normal;text-transform:uppercase}
        .s-contact .container .contacts-left .contact p,.s-contact .container .contacts-right .contact p{
          font-size:1rem;padding-left:0.1rem;color:#218d2a;margin-top:0.2rem
        }
        .s-contact .container .contacts-left .contact span,.s-contact .container .contacts-right .contact span{
          background:#d3e7ad;margin-left:0.1rem;font-size:1.2rem;padding:0.1rem;color:#185c27;margin-top:0.1rem
          }
        @media (max-width: 1000px){
          .s-contact{padding:0}.s-contact .container{
            align-items:center;
            flex-direction:column;
            align-items:center;
            }
          .s-contact .container .contacts-left .contact,.s-contact .container .contacts-right .contact{
            align-items:center;margin:0
          }
          .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
            text-align:left
            }
          }
        @media (max-width: 80px){
          .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
            font-size:2rem;
            }
          }

  @font-face {
    font-family: 'Hero3';
    font-style: normal;
    font-weight: normal;
    src: url(../assets/fonts/Hero New Light.woff);
    }


   




</style>

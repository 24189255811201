<template>
  <v-app>
  <v-main >
         <router-view ></router-view>
  </v-main>
</v-app>
</template>

<script>

export default {
  name: 'App',
 
 data: () => ({
     // color: 'background-color:transparent;',
        //background:#f5f1f1
         //   color: 'background-color:#78909C;',

  }),
}
</script>

<style>

</style>

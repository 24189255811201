import Vue from 'vue'
import VueRouter from 'vue-router'
import Empresa from '../Empresa.vue'
import Parquedasflores from '../views/Parquedasflores.vue'
import Previda from '../views/Previda.vue'
import Memorialparquemaceio from '../views/Memorialparquemaceio.vue'
import Cemiterioprevida from '../views/Cemiterioprevida.vue'
import Parquedoagreste from '../views/Parquedoagreste.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Empresa',
    component: Empresa
  },
  {
    path: '/parquedasflores',
    name: 'Parquedasflores',
    component: Parquedasflores
  },
  {
    path: '/parquedoagreste',
    name: 'Parquedoagreste',
    component: Parquedoagreste
  },
  {
    path: '/cemiterioprevida',
    name: 'Cemiterioprevida',
    component: Cemiterioprevida
  },
  {
    path: '/previda',
    name: 'Previda',
    component: Previda
  },

  {
    path: '/memorialparquemaceio',
    name: 'Memorialparquemaceio',
    component: Memorialparquemaceio
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})


export default router
